.text-primary {
  color: @brand-primary;
}

.text-warning {
  color: @brand-warning;
}

.text-danger {
  color: @brand-danger;
}

.text-success {
  color: @brand-success;
}

.text-accent {
  color: @brand-accent;  
}


.text-rg {
  font-size: @font-size-base;
}
.text-sm {
  font-size: @font-size-small;
}
.text-lg {
  font-size: @font-size-large;
}


.text-muted {
  color: @text-muted;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-right {
  text-align: left;
}

.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}