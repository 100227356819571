.center-children-v {

}

.center-children-v > div {
  display: inline-block;
}

.center-children-v > * {
  vertical-align: middle;
}
