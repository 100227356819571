@import 'util.less';


* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

body, html {
  height: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
}

hr {
  border-width: 0;
  height: 1px;
  background-color: #ddd;
  margin: 15px 0;
}

a, a:visited, a:hover {
  color: @brand-primary;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.content-padding {
  padding: @content-padding;
}

.content-padding-h {
  padding-left: @content-padding;
  padding-right: @content-padding;
}

.content-padding-v {
  padding-top: @content-padding;
  padding-bottom: @content-padding;
}

#app-view {
  position: relative;
  min-height: 100%;
  padding-top: @toolbar-height;
  padding-bottom: @footer-height;  
}

#preloader {
  animation-duration: 1s;
  animation-timing-function: linear;
}

.toolbar-fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 61;
}

.footer-fixed {
  .text-muted;
  background-color: rgb(250, 250, 250);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: @footer-height;
  z-index: 61;
}

.app-header-title {
  font-size: 150%;
  font-weight: lighter;
}

.app-header-title .icon {
  font-size: 150%;
}

.app-header-title .v75 {
  font-size: 120%;
}

.app-header-title .spacy {
  letter-spacing: 5px;
}

#logo-icon {
  animation-delay: 2s;
}

.no-margin {
  margin: 0 !important;
}

.auth-card-wrapper {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.auth-card-wrapper-sm {
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
}

.appdea-create-container {
  margin-bottom: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

.appdea-creator {
  max-width: 500px;
  margin: 0 auto;
}

md-dialog {
  max-width: 600px;
  margin: 0 10px;
}

appdea-view {
  display: block;
}


md-toolbar, footer {
  animation-duration: 1s;
  animation-delay: 1s;
}

.animate-fade.ng-enter {
  position: relative;
  animation: fadeIn 1s;
}

// md-input-container textarea {
//   transition: height ease 0.3s;
// }

md-input-container.no-m {
  margin: 0;
  margin-top: @m-sm;
}

md-input-container.m-sm {
  margin: @m-sm 0;
  margin-top: @m-md;
}

appdea-view [marked] {
  line-height: 150%;
}

appdea-view.ng-move,
appdea-view.ng-enter,
appdea-view.ng-leave {
  transition: all ease 0.5s;
}

appdea-view.ng-enter {
  opacity:0;
  transform: scale(0.5) translateY(-500px);
}

appdea-view.ng-enter.ng-enter-active {
  opacity:1;
  transform: scale(1) translateY(0);
}

appdea-view.ng-leave {
  opacity:1;
  transform: scale(1) translateY(0);
}

appdea-view.ng-leave.ng-leave-active {
  opacity:0;
  transform: scale(0.5) translateY(200px);
}

appdea-view md-toolbar .subtitle {
  font-size: 60%;
}



/*----------
  Account
-----------*/
md-sidenav {
  min-width: 250px;
  max-width: 250px;
  width: 250px;
}

md-sidenav:not(.md-locked-open) {
  padding-top: @toolbar-height;
  // animation-duration: .5s;
  // animation-delay: 1s;
}

.md-sidenav-menu .menu-heading {
  color: @brand-primary;
}

.md-sidenav-menu .md-button.active, .md-sidenav-menu .md-button:hover, .md-sidenav-menu .md-button:focus {
  color: white;
  background: @brand-primary;
}



// appdea-view.ng-move{
//   transform: scale(0.1);
//   opacity:0;
// }

// appdea-view.ng-move.ng-move-active{
//   transform: scale(1);
//   opacity:1;
// }


@media (max-width: 599px) {
  .content-padding {
    padding-left: @content-padding-sm;
    padding-right: @content-padding-sm;
  }
  // .content-padding-v {
  //   padding-top: @content-padding-sm;
  //   padding-bottom: @content-padding-sm;
  // }
  .content-padding-h {
    padding-left: @content-padding-sm;
    padding-right: @content-padding-sm;
  }
}