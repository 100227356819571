/*------------------------------------------------------------------------------
  COLORS
------------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------
  PADDING, MARGIN
------------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------
  TYPO
------------------------------------------------------------------------------*/
.no-border {
  border-color: transparent;
  border-width: 0;
}
.no-borders {
  border-width: 0 !important;
}
.b {
  border: 1px solid rgba(0, 0, 0, 0.05);
}
.b-a {
  border: 1px solid #ddd;
}
.b-t {
  border-top: 1px solid #ddd;
}
.b-r {
  border-right: 1px solid #ddd;
}
.b-b {
  border-bottom: 1px solid #ddd;
}
.b-l {
  border-left: 1px solid #ddd;
}
.b-2x {
  border-width: 2px;
}
.b-3x {
  border-width: 3px;
}
.b-4x {
  border-width: 4px;
}
.b-5x {
  border-width: 5px;
}
.b-t-2x {
  border-top-width: 2px !important;
}
.b-t-3x {
  border-top-width: 3px !important;
}
.b-t-4x {
  border-top-width: 4px !important;
}
.b-t-5x {
  border-top-width: 5px !important;
}
.b-r-2x {
  border-right-width: 2px !important;
}
.b-r-3x {
  border-right-width: 3px !important;
}
.b-r-4x {
  border-right-width: 4px !important;
}
.b-r-5x {
  border-right-width: 5px !important;
}
.b-b-2x {
  border-bottom-width: 2px !important;
}
.b-b-3x {
  border-bottom-width: 3px !important;
}
.b-b-4x {
  border-bottom-width: 4px !important;
}
.b-b-5x {
  border-bottom-width: 5px !important;
}
.b-l-2x {
  border-left-width: 2px !important;
}
.b-l-3x {
  border-left-width: 3px !important;
}
.b-l-4x {
  border-left-width: 4px !important;
}
.b-l-5x {
  border-left-width: 5px !important;
}
.b-light {
  border-color: #f3f3f3;
}
.b-white {
  border-color: #fff;
}
.b-black {
  border-color: #222;
}
.b-t-black {
  border-top-color: #222;
}
.b-r-black {
  border-right-color: #222;
}
.b-b-black {
  border-bottom-color: #222;
}
.b-l-black {
  border-left-color: #222;
}
.b-primary {
  border-color: #1565C0;
}
.b-t-primary {
  border-top-color: #1565C0;
}
.b-r-primary {
  border-right-color: #1565C0;
}
.b-b-primary {
  border-bottom-color: #1565C0;
}
.b-l-primary {
  border-left-color: #1565C0;
}
.b-info {
  border-color: #1565C0;
}
.b-t-info {
  border-top-color: #1565C0;
}
.b-r-info {
  border-right-color: #1565C0;
}
.b-b-info {
  border-bottom-color: #1565C0;
}
.b-l-info {
  border-left-color: #1565C0;
}
.b-success {
  border-color: #2E7D32;
}
.b-t-success {
  border-top-color: #2E7D32;
}
.b-r-success {
  border-right-color: #2E7D32;
}
.b-b-success {
  border-bottom-color: #2E7D32;
}
.b-l-success {
  border-left-color: #2E7D32;
}
.b-warning {
  border-color: #FFB300;
}
.b-t-warning {
  border-top-color: #FFB300;
}
.b-r-warning {
  border-right-color: #FFB300;
}
.b-b-warning {
  border-bottom-color: #FFB300;
}
.b-l-warning {
  border-left-color: #FFB300;
}
.b-danger {
  border-color: #C62828;
}
.b-t-danger {
  border-top-color: #C62828;
}
.b-r-danger {
  border-right-color: #C62828;
}
.b-b-danger {
  border-bottom-color: #C62828;
}
.b-l-danger {
  border-left-color: #C62828;
}
.b-inverse {
  border-color: #f3f3f3;
}
.b-t-inverse {
  border-top-color: #f3f3f3;
}
.b-r-inverse {
  border-right-color: #f3f3f3;
}
.b-b-inverse {
  border-bottom-color: #f3f3f3;
}
.b-l-inverse {
  border-left-color: #f3f3f3;
}
.b-dark {
  border-color: #222;
}
.b-t-dark {
  border-top-color: #222;
}
.b-r-dark {
  border-right-color: #222;
}
.b-b-dark {
  border-bottom-color: #222;
}
.b-l-dark {
  border-left-color: #222;
}
.no-b-t {
  border-top-width: 0;
}
.no-b-r {
  border-right-width: 0;
}
.no-b-b {
  border-bottom-width: 0;
}
.no-b-l {
  border-left-width: 0;
}
.b-dashed {
  border-style: dashed !important;
}
@media (max-width: 991px) {
  .no-border-sm {
    border-width: 0;
  }
}
@media (max-width: 767px) {
  .no-border-xs {
    border-width: 0;
  }
}
.center-children-v > div {
  display: inline-block;
}
.center-children-v > * {
  vertical-align: middle;
}
.no-margin {
  margin: 0 !important;
}
.no-margin-h {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.no-margin-v {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.no-margin-r {
  margin-right: 0 !important;
}
.no-margin-l {
  margin-left: 0 !important;
}
.m {
  margin: 15px;
}
.m-xs {
  margin: 5px;
}
.m-sm {
  margin: 10px;
}
.m-md {
  margin: 20px;
}
.m-lg {
  margin: 30px;
}
.m-v-xs {
  margin-top: 5px;
  margin-bottom: 5px;
}
.m-v-sm {
  margin-top: 10px;
  margin-bottom: 10px;
}
.m-v {
  margin-top: 15px;
  margin-bottom: 15px;
}
.m-v-md {
  margin-top: 20px;
  margin-bottom: 20px;
}
.m-v-lg {
  margin-top: 30px;
  margin-bottom: 30px;
}
.m-h-xs {
  margin-left: 5px;
  margin-right: 5px;
}
.m-h-sm {
  margin-left: 10px;
  margin-right: 10px;
}
.m-h {
  margin-left: 15px;
  margin-right: 15px;
}
.m-h-md {
  margin-left: 20px;
  margin-right: 20px;
}
.m-h-lg {
  margin-left: 30px;
  margin-right: 30px;
}
.m-t {
  margin-top: 15px;
}
.m-t-xs {
  margin-top: 5px;
}
.m-t-sm {
  margin-top: 10px;
}
.m-t-md {
  margin-top: 20px;
}
.m-t-lg {
  margin-top: 30px;
}
.m-r {
  margin-right: 15px;
}
.m-r-xs {
  margin-right: 5px;
}
.m-r-sm {
  margin-right: 10px;
}
.m-r-md {
  margin-right: 20px;
}
.m-r-lg {
  margin-right: 30px;
}
.m-b {
  margin-bottom: 15px;
}
.m-b-xs {
  margin-bottom: 5px;
}
.m-b-sm {
  margin-bottom: 10px;
}
.m-b-md {
  margin-bottom: 20px;
}
.m-b-lg {
  margin-bottom: 30px;
}
.m-l {
  margin-left: 15px;
}
.m-l-xs {
  margin-left: 5px;
}
.m-l-sm {
  margin-left: 10px;
}
.m-l-md {
  margin-left: 20px;
}
.m-l-lg {
  margin-left: 30px;
}
.m-n {
  margin: -15px;
}
.m-h-n {
  margin-left: -15px;
  margin-right: -15px;
}
.m-v-n {
  margin-top: -15px;
  margin-bottom: -15px;
}
.m-l-n {
  margin-left: -15px;
}
.m-r-n {
  margin-right: -15px;
}
.m-t-n {
  margin-top: -15px;
}
.m-b-n {
  margin-bottom: -15px;
}
.no-padding {
  padding: 0 !important;
}
.no-padding-h {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.no-padding-v {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.no-padding-r {
  padding-right: 0 !important;
}
.no-padding-l {
  padding-left: 0 !important;
}
.p-xs {
  padding: 5px;
}
.p-sm {
  padding: 10px;
}
.p {
  padding: 15px;
}
.p-md {
  padding: 20px;
}
.p-lg {
  padding: 30px;
}
.p-h {
  padding-left: 15px;
  padding-right: 15px;
}
.p-h-xs {
  padding-left: 5px;
  padding-right: 5px;
}
.p-h-sm {
  padding-left: 10px;
  padding-right: 10px;
}
.p-h-md {
  padding-left: 20px;
  padding-right: 20px;
}
.p-h-lg {
  padding-left: 30px;
  padding-right: 30px;
}
.p-v {
  padding-top: 15px;
  padding-bottom: 15px;
}
.p-v-xs {
  padding-top: 5px;
  padding-bottom: 5px;
}
.p-v-sm {
  padding-top: 10px;
  padding-bottom: 10px;
}
.p-v-md {
  padding-top: 20px;
  padding-bottom: 20px;
}
.p-v-lg {
  padding-top: 30px;
  padding-bottom: 30px;
}
.text-primary {
  color: #1565C0;
}
.text-warning {
  color: #FFB300;
}
.text-danger {
  color: #C62828;
}
.text-success {
  color: #2E7D32;
}
.text-accent {
  color: #FF0;
}
.text-rg {
  font-size: 1em;
}
.text-sm {
  font-size: 0.8em;
}
.text-lg {
  font-size: 1.2em;
}
.text-muted {
  color: #777;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-right {
  text-align: left;
}
.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
}
.text-lowercase {
  text-transform: lowercase;
}
.text-uppercase {
  text-transform: uppercase;
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
body,
html {
  height: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
}
hr {
  border-width: 0;
  height: 1px;
  background-color: #ddd;
  margin: 15px 0;
}
a,
a:visited,
a:hover {
  color: #1565C0;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
.content-padding {
  padding: 20px;
}
.content-padding-h {
  padding-left: 20px;
  padding-right: 20px;
}
.content-padding-v {
  padding-top: 20px;
  padding-bottom: 20px;
}
#app-view {
  position: relative;
  min-height: 100%;
  padding-top: 64px;
  padding-bottom: 56px;
}
#preloader {
  animation-duration: 1s;
  animation-timing-function: linear;
}
.toolbar-fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 61;
}
.footer-fixed {
  color: #777;
  background-color: #fafafa;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 56px;
  z-index: 61;
}
.app-header-title {
  font-size: 150%;
  font-weight: lighter;
}
.app-header-title .icon {
  font-size: 150%;
}
.app-header-title .v75 {
  font-size: 120%;
}
.app-header-title .spacy {
  letter-spacing: 5px;
}
#logo-icon {
  animation-delay: 2s;
}
.no-margin {
  margin: 0 !important;
}
.auth-card-wrapper {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}
.auth-card-wrapper-sm {
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
}
.appdea-create-container {
  margin-bottom: 20px;
  margin-left: 10px;
  margin-right: 10px;
}
.appdea-creator {
  max-width: 500px;
  margin: 0 auto;
}
md-dialog {
  max-width: 600px;
  margin: 0 10px;
}
appdea-view {
  display: block;
}
md-toolbar,
footer {
  animation-duration: 1s;
  animation-delay: 1s;
}
.animate-fade.ng-enter {
  position: relative;
  animation: fadeIn 1s;
}
md-input-container.no-m {
  margin: 0;
  margin-top: 10px;
}
md-input-container.m-sm {
  margin: 10px 0;
  margin-top: 20px;
}
appdea-view [marked] {
  line-height: 150%;
}
appdea-view.ng-move,
appdea-view.ng-enter,
appdea-view.ng-leave {
  transition: all ease 0.5s;
}
appdea-view.ng-enter {
  opacity: 0;
  transform: scale(0.5) translateY(-500px);
}
appdea-view.ng-enter.ng-enter-active {
  opacity: 1;
  transform: scale(1) translateY(0);
}
appdea-view.ng-leave {
  opacity: 1;
  transform: scale(1) translateY(0);
}
appdea-view.ng-leave.ng-leave-active {
  opacity: 0;
  transform: scale(0.5) translateY(200px);
}
appdea-view md-toolbar .subtitle {
  font-size: 60%;
}
/*----------
  Account
-----------*/
md-sidenav {
  min-width: 250px;
  max-width: 250px;
  width: 250px;
}
md-sidenav:not(.md-locked-open) {
  padding-top: 64px;
}
.md-sidenav-menu .menu-heading {
  color: #1565C0;
}
.md-sidenav-menu .md-button.active,
.md-sidenav-menu .md-button:hover,
.md-sidenav-menu .md-button:focus {
  color: white;
  background: #1565C0;
}
@media (max-width: 599px) {
  .content-padding {
    padding-left: 0;
    padding-right: 0;
  }
  .content-padding-h {
    padding-left: 0;
    padding-right: 0;
  }
}
