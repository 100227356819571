
.no-padding {
  padding: 0 !important;
}
.no-padding-h {
  padding-left : 0 !important;
  padding-right: 0 !important;
}
.no-padding-v {
  padding-top   : 0 !important;
  padding-bottom: 0 !important;
}
.no-padding-r {
  padding-right: 0 !important;
}
.no-padding-l {
  padding-left : 0 !important;
}
.p-xs {
  padding: @p-xs;
}
.p-sm {
  padding: @p-sm;
}
.p {
  padding: @p;
}
.p-md {
  padding: @p-md;
}
.p-lg {
  padding: @p-lg;
}
.p-h {
  padding-left : @p;
  padding-right: @p;
}
.p-h-xs {
  padding-left : @p-xs;
  padding-right: @p-xs;
}
.p-h-sm {
  padding-left : @p-sm;
  padding-right: @p-sm;
}
.p-h-md {
  padding-left : @p-md;
  padding-right: @p-md;
}
.p-h-lg {
  padding-left : @p-lg;
  padding-right: @p-lg;
}
.p-v {
  padding-top   : @p;
  padding-bottom: @p;
}
.p-v-xs {
  padding-top   : @p-xs;
  padding-bottom: @p-xs;
}
.p-v-sm {
  padding-top   : @p-sm;
  padding-bottom: @p-sm;
}
.p-v-md {
  padding-top   : @p-md;
  padding-bottom: @p-md;
}
.p-v-lg {
  padding-top   : @p-lg;
  padding-bottom: @p-lg;
}
